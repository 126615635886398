.loginById {
}

.submmitBtn {
    width: 135px;
    height: 30px;
    overflow: hidden;
    zoom: 1;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    background: #338de7;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-left: 76px;
}

.errrTip {
    width: 100%;
    font-size: 12px;
    color: #f54343;
    text-align: center;
}
