.header {
    composes: clearfix from global;
    border-bottom: 1px solid #d9d9d9;
    height: 50px;
    background: #fff;
    width: 100%;
}

.logo {
    width: 126px;
    display: block;
    text-indent: -99999px;
    float: left;
    margin: 10px 0 0 30px;
    height: 34px;
    background: none;
}

.logo img {
    position: relative;
    height: 34px;
    display: block;
}
