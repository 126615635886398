.formContent {
    width: 604px;
    float: left;
    clear: both;
    position: relative;
    left: 50%;
    margin-left: -302px;
    padding: 50px 0 130px;
}

.title {
    margin: 0 0 24px 0;
    font-weight: normal;
    font-family: '微软雅黑';
    display: block;
    text-align: center;
    font-size: 24px;
}

.box {
    *zoom: 1;
    width: 603px;
    background: #fff url('//x0.ifengimg.com/ucms/2019_44/A7AEA8911D420919FA97578F11E77D7FDBEA82E0_w3_h1.gif') repeat-y
        right 0;
    border-top: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
}

.formList {
    padding: 46px 0 30px 48px;
    min-height: 246px;
}

.bot {
    height: 8px;
    width: 604px;
    overflow: hidden;
    clear: both;
    background: url('//x0.ifengimg.com/ucms/2019_44/BB4D30D0DE1C4B669821F15945FE2E26E78B87BA_w604_h8.gif') no-repeat;
    margin-left: -1px;
}
