.autoLogin {
    composes: clearfix from global;
    padding-left: 76px;
    height: 30px;
}

.checkBox {
    float: left;
    cursor: pointer;
}

.txt {
    color: #666;
    padding: 0;
    margin: 0 0 0 6px;
}

.register {
    float: right;
    font-size: 12px;
    color: #666;
}

.register:link,
.register:visited {
    float: right;
    font-size: 12px;
    color: #666;
}
