.vCode {
    composes: clearfix from global;
    margin-bottom: 14px;
    line-height: 32px;
}
.vCodeInput {
    composes: clearfix from global;
    margin-bottom: 16px;
    display: block;
    line-height: 32px;
    float: left;
}

.label {
    font-size: 14px;
    width: 60px;
    text-align: right;
    float: left;
}

.input {
    float: left;
    width: 124px;
    margin: 0 12px 0 16px;
}

.codeImg {
    width: 60px;
    height: 32px;
    float: left;
}

.codeImg img {
    width: 100%;
    cursor: pointer;
    height: 32px;
}

.refresh {
    color: #004276;
    cursor: pointer;
    margin-left: 12px;
    float: left;
    line-height: 32px;
}
.error {
    margin-left: 38px;
    float: left;
    background: url('//x0.ifengimg.com/ucms/2019_44/2C3DA68CFB0600EBC48229ACD1C7DE2CFA8B38E9_w18_h18.gif') no-repeat 0
        center;
    width: 136px;
    padding-left: 24px;
    color: #ba2636;
}
