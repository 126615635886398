.Input {
    height: 30px;
    border: 1px solid #e9e9e9;
    overflow: hidden;
    & input {
        width: 100%;
        height: 30px;
        outline: none;
        border: 0;
        padding: 0 6px;
        font-size: 16px;
        box-sizing: border-box;
        line-height: 30px;
        color: #666;
    }
    & [disabled] {
        background-color: #f6f6f6;
        color: #ccc;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
    }
    & input::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #bfbfbf;
        font-size: 14px;
    }
    & input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #bfbfbf;
        font-size: 14px;
    }
    & input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #bfbfbf;
        font-size: 14px;
    }
    & input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #bfbfbf;
        font-size: 14px;
    }
}
.error {
    border: 1px solid #f54343;
}
