.userName {
    composes: clearfix from global;
    margin-bottom: 16px;
    display: block;
    line-height: 32px;
}

.label {
    font-size: 14px;
    width: 60px;
    text-align: right;
    float: left;
}

.input {
    float: left;
    width: 270px;
    margin: 0 12px 0 16px;
}

.loginregi {
    float: right;
    display: inline-block;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    color: #0e74e8;
}

.loginregi:link,
.loginregi:visited {
    color: #0e74e8;
}

.error {
    float: left;
    background: url('//x0.ifengimg.com/ucms/2019_44/2C3DA68CFB0600EBC48229ACD1C7DE2CFA8B38E9_w18_h18.gif') no-repeat 0
        center;
    width: 136px;
    padding-left: 24px;
    color: #ba2636;
}
