.aside {
    position: absolute;
    right: 0px;
    width: 120px;
    top: 50px;
}

.aside h3 {
    font-size: 12px;
    font-weight: normal;
    margin: 5px 0 30px;
}

.aside h3 a:link,
.aside h3 a:visited {
    color: #004276;
    text-decoration: none;
}

.aside .item {
    float: left;
    padding-bottom: 12px;
    margin-right: 5px;
}

.gray {
    padding-bottom: 12px;
    color: #666 !important;
}

.linkwx {
    cursor: pointer;
    width: 34px;
    height: 34px;
    background: url(//p1.ifengimg.com/allsite/login/icon_wechat.png) no-repeat center;
    margin: 0 auto;
}

.linkqq {
    composes: linkwx;
    background: url(//p1.ifengimg.com/allsite/login/icon_qq.png) no-repeat center;
}

.linkweibo {
    composes: linkwx;
    background: url(//p1.ifengimg.com/allsite/login/icon_sina.png) no-repeat center;
}
